<template lang="pug">
.template-chooser-custom-templates(ref="block")
  ListEmptyState(
    v-if="!getPossibleTemplates().length"
    :image="require('@/assets/admin/svg/monk_magnifying.svg')"
    :title="$t('templateChooser.emptyStateBaseTitle')"
  )
  .row(v-else)
    template(v-for="(template, index) in seasonalsSortedBackwards")
      .col-12.mb-6(v-if="!isActiveFilters && index === 30")
        filter-banner
      .col-4.mb-6
        TemplateBox(
          :title="getTitle(template.useCase)"
          @inited="updateDimensions"
          @observable="addObservable($event.$el)"
          :dimensions="boxDimensions"
          :template="template"
          heap-event="new-selector-campaign-more-templates"
        )
  .template-chooser-custom-templates-more-handler(ref="loadingHandler" style="width: 100%")
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import ssrMixin from '@/mixins/ssr';
  import homepageBlockMixin from '../../mixins/homepageBlock';
  import templateBoxParentMixin from '../../mixins/templateBoxParent';
  import filterHelperMixin from '../../mixins/filterHelper';

  export default {
    name: 'CustomTemplates',
    components: {
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
      TemplateBox: () => import('@/components/TemplateChooser/components/TemplateBox.vue'),
      FilterBanner: () => import('@/components/TemplateChooser/components/FilterBanner.vue'),
      ListEmptyState: () => import('@/components/TemplateChooser/components/ListEmptyState.vue'),
    },
    mixins: [
      homepageBlockMixin,
      ssrMixin,
      observableCollectionMixin,
      templateBoxParentMixin,
      filterHelperMixin,
    ],
    props: {
      rootMargin: { type: String },
    },
    data: () => ({
      templateCount: 12,
      isObserverSupported: true,
      observer: null,
      ssrBoxSelector: '.template-chooser-custom-templates .template-thumbnail-box',
    }),
    computed: {
      ...mapState(['accountType']),
      ...mapState('templateChooser', ['filter']),
      ...mapGetters('templateChooser', ['seasonalsSortedBackwards']),
      ...mapState('useCase', ['useCaseMap']),
    },
    beforeDestroy() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
        this.intersectionObserver = null;
      }
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },
    mounted() {
      if (this.$refs.block) {
        this.$refs.block.__comp__ = this;
        this.addObservable(this.$refs.block);
      }
    },
    methods: {
      onIntersecting() {
        this.updateDimensions();
      },
      onNotIntersecting() {},
      initObserver() {
        if (
          this.observer ||
          !this.isObserverSupported ||
          (!('IntersectionObserver' in window) &&
            !('IntersectionObserverEntry' in window) &&
            !('intersectionRatio' in window.IntersectionObserverEntry.prototype))
        ) {
          this.isObserverSupported = false;
          return;
        }
        const options = {
          root: document.querySelector('body'),
          rootMargin: '300px 0px',
          threshold: 1.0,
        };
        this.observer = new IntersectionObserver(this.onIntersection, options);
        this.observer.observe(this.$refs.loadingHandler);
      },
      onIntersection(entries) {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting) this.templateCount += 24;
        });
      },
      getPossibleTemplates() {
        return this.seasonalsSortedBackwards;
      },
      getTitle(useCaseId) {
        return this.useCaseMap?.[useCaseId]?.name?.[this.$i18n.locale];
      },
    },
  };
</script>
